<template>
  <div>
    <div class="">
      <svg
        class="mx-4"
        v-if="state == 'green' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#46634F"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'yellow' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#E3C10C"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'red' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#D31515"
        />
      </svg>
      <svg
        class="mx-4"
        v-if="state == 'orange' "
        width="19"
        height="19"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="11.5"
          cy="11.5"
          r="11.5"
          fill="#FFA500"
        />
      </svg>
    </div>
    <slot />
  </div>
</template>

<script>
export default {

  props: {
    state: {
      type: String,
      required: true
    }
  }
}
</script>

  <style>

  </style>
